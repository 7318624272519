import React, { useState} from "react";
import axios from 'axios';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Logo from '../img/logo.png';
import BgLogin from '../img/ilustration.png';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useLocation, useHistory } from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';
import Slider from "react-slick";
import BannerRegis1 from '../img/slide-reg-1.png';
import BannerRegis2 from '../img/slide-reg-2.png';
import { baseUrl } from 'utils';

const settings = {
  dots: false,
  infinite: true,
  arrows:false,
  autoplay:true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
      arrows:false,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 0
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
};

const useStyles = makeStyles((theme) => ({
  MainLogin:{
    backgroundColor: '#fff',
    marginBottom:'30px',
    [theme.breakpoints.up('md')]: {
      marginTop:'40px'
    },
  },
  sectionDesktop: {
    display: 'none',
    alignItems:'center',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(8),
    },   
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  textCenter:{
    textAlign:'center',
  },
  sexState:{
    width: '47%',
    float: 'left',
    margin: '0 5px'
  },
  formControl:{
    width:'100%',
    marginBottom:'10px'
  }
}));

export default function Register() {
  ReactPixel.init('829256154386906', {}, { debug: true, autoConfig: false });
  ReactPixel.pageView();
  ReactPixel.fbq('track', 'AddToCart');

  const classes = useStyles();
  const [password, setPassword] = useState(''); 
  const location = useLocation();
  const username = location.state.params;
  const history = useHistory();
  const MySwal = withReactContent(Swal)

  const handleSubmit = async e => {   
    e.preventDefault();  
    await axios.post(`${baseUrl}/auth/verify`, {
        username,
        password
    }).then((result) => {
      
      const dataToken = result.data.data.token;
      localStorage.setItem("tokenRegister", JSON.stringify(dataToken));      
      MySwal.fire({
        icon: 'success',
        title: "Verifikasi Berhasil!",
      }).then((result)=>{
        // window.location.href="/reg/final"
        history.push('/reg/final',{params: username})
      })
        
    }).catch(e => {
      MySwal.fire({
        icon: 'error',
        title: "Maaf kode yang anda masukan salah / sudah expired!",
      })
    });    

   
  };

  return (
    <div className={classes.MainLogin}>
    <Grid container spacing={0}>
        <Grid item xs={12} md={6}>
        <Slider {...settings}>
          <div>
            <img src={BannerRegis1} width="100%"/>
          </div>
          <div>
            <img src={BannerRegis2} width="100%"/>
          </div>
        </Slider>
        </Grid>
        <Grid item xs={12} md={6}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
          <div className={classes.sectionDesktop}>
            <img src={Logo} style={{ height: 80, width: 80 }} alt=""/>   
          </div>       
            <form className={classes.form} onSubmit={handleSubmit} noValidate >
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Email / No Hp"
                name="username"
                autoComplete='off'
                value={username}
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="OTP"
                type="password"
                id="password"
                onChange={e => setPassword(e.target.value)}
                autoComplete='off'
              />
              
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Verifikasi
              </Button>
 
            </form>
          </div>
          
        </Container>
        </Grid>
        
    </Grid>
    </div>
  );
}


