import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    terms:{
        margin:'0', padding:'2em',
        [theme.breakpoints.up('md')]: {
            margin:'6em', padding:'2em'
          }
    }
}))
function TermCondition() {
  const classes = useStyles();
  const theme = useTheme();
    return (
        <div className={classes.terms}>
            <h2>SYARAT DAN KETENTUAN</h2>
            <p> Pengguna dalam hal ini disebut sebagai Mitra Leholeh patuh dan tunduk pada Kebijakan Privasi dan Syarat dan Ketentuan yang tertulis di bawah ini. 
            Pengguna disarankan untuk membaca dan memahami ketentuan ini secara benar, dikarenakan akan menimbulkan hak dan kewajiban antara 
            PT. Leholeh Rasa Nusantara Jaya dengan Pengguna secara hukum dengan ketentuan peraturan perundang-undangan yang berlaku.</p>
            <p>Dengan mendaftar dan/atau menggunakan menjadi mitra Leholeh, maka pengguna dianggap telah membaca, mengerti, memahami dan menyetujui semua isi dalam Syarat dan Ketentuan ini. Syarat dan Ketentuan ini merupakan bentuk kesepakatan yang akan dituangkan selanjutnya dalam sebuah perjanjian yang sah antara  PT. Leholeh Rasa Nusantara Jaya dengan mitra. Jika pengguna tidak menyetujui salah satu, sebagian, atau seluruh isi Syarat dan Ketentuan, maka pengguna tidak diperkenankan untuk menggunakan pelayanan di leholeh.co.id</p>

            <h4>A. DEFINISI</h4>
            <ol>
                <li>Leholeh adalah PT. Leholeh Rasa Nusantara Jaya suatu perseroan terbatas yang menjalankan kegiatan usaha jasa di web portal www.leholeh.co.id.</li>
                <li>Situs/Aplikasi adalah situs www.leholeh.co.id yang dapat diakses melalui desktop site, mobile web, dan/atau aplikasi yang berbasis Android atau iOS.</li>
                <li>Pengguna adalah pihak yang menggunakan layanan Leholeh, bertindak selaku pembeli suatu barang di layanan Leholeh. Namun tidak terbatas pada Mitra Leholeh ataupun pihak lain dan hanya sekedar berkunjung ke Situs/Aplikasi</li>
                <li>Partner adalah Pengguna yang bertindak selaku Penjual terdaftar yang melakukan tindakan buka toko dan/atau melakukan penawaran atas suatu barang kepada para Pengguna Situs Leholeh, termasuk pihak ketiga yang bekerjasama dengan Leholeh</li>
                <li>Mitra Leholeh adalah Pengguna yang telah mendaftarkan diri melalui Situs/Aplikasi untuk membeli Barang Digital maupun barang Fisik dari Partner melalui Situs/Aplikasi dan menjualnya kembali secara offline.</li>
            </ol>

            <h4>B. KETENTUAN UMUM</h4>
            <ol>
                <li>Mitra Leholeh hanya dapat digunakan oleh pengguna yang telah mendaftarkan diri, menyetujui Syarat dan Ketentuan ini serta yang sudah diverifikasi sesuai kebijakan dari Leholeh.</li>
                <li>Mitra Leholeh adalah orang yang cakap dan mampu untuk mengikatkan dirinya dalam sebuah perjanjian yang sah menurut hukum.</li>
                <li>Leholeh tidak memungut biaya pendaftaran kepada Mitra Leholeh.</li>
                <li>Leholeh berhak untuk menggunakan data Mitra Leholeh untuk penelusuran indikasi manipulasi, pelanggaran maupun pemanfaatan fitur Leholeh untuk keuntungan pribadi Mitra Leholeh, maupun indikasi kecurangan atau pelanggaran syarat dan ketentuan ini, ketentuan situs, dan/atau ketentuan hukum yang berlaku di wilayah negara Indonesia.</li>
                <li>Leholeh berhak tanpa pemberitahuan sebelumnya, melakukan tindakan-tindakan yang diperlukan namun tidak terbatas pada penghentian penggunaan Situs/Aplikasi, membatalkan transaksi, menahan dana, menurunkan reputasi, menutup akun, serta hal-hal lainnya jika ditemukan adanya manipulasi, pelanggaran maupun pemanfaatan untuk keuntungan pribadi Mitra Leholeh, maupun indikasi kecurangan atau pelanggaran syarat dan ketentuan ini, ketentuan situs, dan/atau ketentuan hukum yang berlaku di wilayah negara Indonesia.</li>
                <li>Leholeh berhak untuk melakukan perubahan atas syarat dan ketentuan ini.</li>
            </ol>

            <h4>C. PENGAJUAN MITRA LEHOLEH</h4>
            <ol>
                <li>Dalam hal pengajuan mitra Leholeh, maka pengguna harus mengajukan permohonan melalui formulir yang telah disediakan oleh Leholeh.</li>
                <li>Proses verifikasi akan dilakukan oleh Leholeh dalam jangka waktu 2x24 jam atau jangka waktu lain sesuai dengan kebijakan Leholeh.</li>
                <li>Mitra Leholeh menjamin dan bertanggungjawab atas informasi dan dokumen yang diberikan dalam mengajukan permohonan untuk mendaftar sebagai Mitra Leholeh.</li>
                <li>Mitra Leholeh menyetujui bahwa informasi dan dokumen yang diberikan pada saat melakukan pendaftaran dapat dibagikan dan/atau disampaikan oleh Leholeh kepada pihak ketiga untuk proses pelaksanaan verifikasi atau kegiatan lainnya sehubungan dengan pendaftaran Mitra Leholeh.</li>
                <li>Mitra Leholeh menyetujui dan memberikan wewenang kepada Leholeh untuk mengumpulkan dan mengolah informasi yang terdapat dalam formulir laman Mitra Leholeh pada Situs/Aplikasi kepada Partner.</li>
                <li>Pengguna menyetujui dan memberikan wewenang kepada Leholeh untuk memberikan informasi dan/atau data relevan lainnya pada Situs/Aplikasi yang dibutuhkan oleh Mitra Leholeh.</li>
            </ol>

            <h4>D. AKUN, PASSWORD, DAN KEAMANAN</h4>
            <ol>
                <li>Leholeh tanpa pemberitahuan terlebih dahulu kepada Mitra Leholeh, memiliki kewenangan untuk melakukan tindakan yang perlu atas setiap dugaan pelanggaran atau pelanggaran Syarat dan Ketentuan ini dan/atau hukum yang berlaku, yakni tindakan berupa melakukansuspend akun, dan/atau penghapusan akun Mitra Leholeh.</li>
                <li>Mitra Leholeh dilarang untuk menciptakan dan/atau menggunakan perangkat, software, fitur dan/atau alat lainnya yang bertujuan untuk melakukan manipulasi pada sistem Leholeh, termasuk namun tidak terbatas pada:
                    <div style={{margin:'0 20px'}}>
                        <div>a.	Manipulasi data;</div>
                        <div>b.	Kegiatan peramban (crawling/scraping);</div>
                        <div>c.	Kegiatan otomatisasi dalam transaksi, jual beli, promosi, dsb; dan/atau</div>
                        <div>d.	Aktivitas lain yang secara wajar dapat dinilai sebagai tindakan manipulasi sistem.</div>
                    </div>                    
                </li>
                <li>Pengguna bertanggung jawab secara pribadi untuk menjaga kerahasiaan akun dan password untuk semua aktivitas yang terjadi dalam akun Pengguna.</li>
                <li>Leholeh tidak akan meminta username, password maupun kode SMS verifikasi atau kode one time password (“OTP”) milik akun Pengguna untuk alasan apapun, oleh karena itu, Leholeh menghimbau Pengguna agar tidak memberikan data tersebut maupun data penting lainnya kepada pihak yang mengatasnamakan Leholeh atau pihak lain yang tidak dapat dijamin keamanannya.</li>
                <li>Pengguna setuju untuk memastikan bahwa Pengguna keluar dari akun di akhir setiap sesi dan memberitahu Leholeh jika ada penggunaan tanpa izin atas sandi atau akun Pengguna.</li>
                <li>Pengguna dengan ini menyatakan bahwa Leholeh tidak bertanggung jawab atas kerugian ataupun kendala yang timbul atas penyalahgunaan akun Pengguna yang diakibatkan oleh kelalaian Pengguna, termasuk namun tidak terbatas pada meminjamkan atau memberikan akses akun kepada pihak lain, mengakses link atau tautan yang diberikan oleh pihak lain, memberikan atau memperlihatkan kode verifikasi OTP, password atau email kepada pihak lain, maupun kelalaian Pengguna lainnya yang mengakibatkan kerugian ataupun kendala pada akun Pengguna.</li>
                <li>Pengguna memahami dan menyetujui bahwa untuk mempergunakan fasilitas keamanan OTP maka penyedia jasa telekomunikasi terkait dapat sewaktu-waktu mengenakan biaya kepada Pengguna dengan nominal sebagai berikut:
                    <div style={{margin:'0 20px'}}>
                        <div> a.Rp 500, - (lima ratus rupiah) ditambah pajak 10% (sepuluh persen) untuk Indosat, Tri, XL, Smartfren, dan Esia; dan </div>
                        <div> b.Rp 200,- (dua ratus rupiah) ditambah pajak 10% (sepuluh persen) untuk Telkomsel.</div>
                    </div>
                </li>

            </ol>

            <h4>E. TRANSAKSI</h4>
            <ol>
                <li>Pengguna wajib bertransaksi melalui prosedur transaksi yang telah ditetapkan oleh Leholeh.</li>
                <li>Saat melakukan pembelian Barang Fisik dan/atau Barang Digital, Mitra Leholeh menyetujui bahwa:
                    <div style={{margin:'0 20px'}}>
                        <div>a.	Bertanggung jawab untuk membaca, memahami, dan menyetujui informasi/deskripsi keseluruhan Barang Fisik dan/atau Barang Digital (termasuk didalamnya namun tidak terbatas pada warna, kualitas, fungsi, dan lainnya) sebelum membuat tawaran atau komitmen untuk membeli.</div>
                        <div>b.	Mengakui bahwa warna sebenarnya dari Barang Fisik sebagaimana terlihat di Situs/Aplikasi tergantung pada perangkat elektronik Mitra Leholeh. Leholeh telah melakukan upaya terbaik untuk memastikan warna dalam foto-foto yang ditampilkan di Situs/Aplikasi muncul seakurat mungkin, tetapi tidak dapat menjamin bahwa penampilan warna pada Situs/Aplikasi akan akurat.</div>
                        <div>c.	Masuk ke dalam kontrak yang mengikat secara hukum untuk membeli Barang  Fisik dan/atau Barang Digital ketika Mitra Leholeh membeli suatu barang.</div>
                        <div>d.	Tidak dapat mengajukan pengembalian Barang Fisik setelah menandatangani dokumen-dokumen terkait pengiriman Barang Fisik dari Mitra Leholeh dan melewati proses quality control oleh Mitra Leholeh.</div>
                        <div>e.	Leholeh tidak mengalihkan kepemilikan secara hukum atas Barang Fisik dan/atau Barang Digital dari Partner kepada Mitra Leholeh.</div>
                    </div>
                </li>
                <li>Pengguna memahami dan menyetujui bahwa ketersediaan stock Barang Fisik merupakan tanggung jawab Mitra Leholeh yang menawarkan Barang Fisik tersebut. Ketersediaan stock Barang Fisik dapat berubah sewaktu-waktu, sehingga dalam keadaan stock Barang Fisik tidak sesuai atau kurang dari jumlah yang dipesan oleh Pengguna, maka Mitra Leholeh akan tetap melakukan pengiriman sesuai dengan stock yang dimilikinya, dimana selisih pembayaran akibat perbedaan jumlah stock dan pengiriman tersebut dikembalikan kepada Pengguna.</li>
                <li>Pengguna memahami sepenuhnya dan menyetujui bahwa segala transaksi yang dilakukan antara Pengguna dengan Mitra Leholeh selain melalui Situs/Aplikasi dan/atau tanpa sepengetahuan Leholeh (melalui fasilitas/jaringan pribadi, pengiriman pesan, pengaturan transaksi khusus diluar Situs/Aplikasi atau upaya lainnya) adalah merupakan tanggung jawab pribadi dari Pengguna.</li>
                <li>Leholeh memiliki kewenangan sepenuhnya untuk menolak pembayaran tanpa pemberitahuan terlebih dahulu.</li>
                <li>Pengguna menyetujui untuk tidak memberitahukan atau menyerahkan bukti pembayaran dan/atau data pembayaran kepada pihak lain selain Leholeh. Dalam hal terjadi kerugian akibat pemberitahuan atau penyerahan bukti pembayaran dan/atau data pembayaran oleh Pengguna kepada pihak lain, maka hal tersebut akan menjadi tanggung jawab Pengguna secara pribadi.</li>
                <li>Mitra Leholeh wajib melakukan konfirmasi penerimaan Barang Fisik, dengan cara menandatangani segala dokumen terkait penerimaan Barang Fisik yang disediakan oleh penyedia jasa layanan pengiriman dan/atau Partner, setelah Mitra Leholeh menerima pengiriman Barang Fisik yang dibeli.</li>
                <li>Mitra Leholeh memahami dan menyetujui bahwa hanya dapat melakukan penerimaan Barang secara keseluruhan, apabila Mitra Leholeh tidak bersedia menerima sebagian dari jumlah Barang yang dikirimkan dikarenakan alasan tertentu, maka Mitra Leholeh harus menolak penerimaan Barang secara keseluruhan dimana penolakan dan alasannya akan disebutkan dalam dokumen terkait pengiriman Barang Fisik yang harus ditandatangani oleh Mitra Leholeh</li>
                <li>Pengguna memahami dan menyetujui bahwa setiap masalah pengiriman Barang Fisik yang disebabkan keterlambatan pembayaran adalah merupakan tanggung jawab dari Pengguna.</li>
                <li>Pengembalian dana dari Leholeh kepada Pengguna, baik sebagian maupun seluruhnya, hanya dapat dilakukan jika dalam keadaan-keadaan tertentu berikut ini:
                    <div style={{margin:'0 20px'}}>
                        <div>a.	Pengguna menolak penerimaan seluruh Barang Fisik yang dikirimkan oleh Mitra Leholeh, dikarenakan alasan tertentu yang disebutkan dalam dokumen terkait pengiriman Barang Fisik yang harus ditandatangani oleh Pengguna.</div>
                        <div>b.	Kelebihan pembayaran dari Pengguna atas harga Barang Fisik,</div>
                        <div>c.	Masalah pengiriman Barang Fisik telah teridentifikasi secara jelas dari Mitra Leholeh yang mengakibatkan pesanan Barang Fisik tidak sampai,</div>
                        <div>d.	Mitra Leholeh tidak bisa menyanggupi pesanan karena kehabisan stok Barang Fisik, maupun penyebab lainnya,</div>
                        <div>e.	Mitra Leholeh sudah menyanggupi pengiriman Barang Fisik, tetapi Mitra Leholeh tidak mengirimkan Barang Fisik hingga batas waktu yang telah ditentukan.</div>
                        <div>f.	Penyelesaian permasalahan melalui Live Chat Mitra Leholeh berupa keputusan untuk pengembalian dana kepada Pengguna atau hasil keputusan dari Leholeh.</div>                 
                    </div>
                </li>
                <li>Leholeh berwenang untuk memberikan fasilitas dan menentukan solusi atas permasalahan-permasalahan transaksi yang belum terselesaikan akibat tidak adanya kesepakatan penyelesaian, baik antara Pengguna dan Mitra Leholeh, dengan melihat bukti-bukti yang ada. Keputusan Leholeh adalah keputusan akhir yang tidak dapat diganggu gugat dan mengikat Pengguna dan Mitra Leholeh untuk mematuhinya.</li>
                <li>Pengguna wajib melakukan pembayaran dengan nominal yang sesuai dengan jumlah tagihan beserta kode unik (apabila ada) yang tertera pada halaman pembayaran. Leholeh tidak bertanggung jawab atas kerugian yang dialami Pengguna apabila melakukan pembayaran yang tidak sesuai dengan jumlah tagihan yang tertera pada halaman pembayaran.</li>
                <li>Pengguna memahami sepenuhnya dan menyetujui bahwa invoice yang diterbitkan adalah atas nama Mitra Leholeh.</li>
            </ol>

            <h4>F. HARGA</h4>
            <ol>
                <li>Harga Barang Fisik yang terdapat dalam Situs/Aplikasi adalah harga yang ditetapkan oleh Mitra Leholeh.</li>
                <li>Pengguna memahami dan menyetujui bahwa kesalahan keterangan harga dan informasi lainnya yang disebabkan tidak terbaharuinya Situs/Aplikasi dikarenakan browser/ISP yang dipakai Pengguna adalah tanggung jawab Pengguna.</li>
                <li>Pengguna memahami dan menyetujui bahwa setiap masalah dan/atau perselisihan yang terjadi akibat ketidaksepahaman antara Pengguna dan Mitra Leholeh tentang harga bukanlah merupakan tanggung jawab Leholeh.</li>
                <li>Dengan melakukan pemesanan melalui Leholeh, Pengguna menyetujui untuk membayar total biaya yang harus dibayarkan sebagaimana tertera dalam halaman pembayaran, yang terdiri dari harga barang, dan biaya-biaya lain yang mungkin timbul dan akan diuraikan secara tegas dalam halaman pembayaran. Pengguna setuju untuk melakukan pembayaran.</li>
            </ol>

            <h4>G.	PROMO MITRA LEHOLEH</h4>
            <ol>
                <li>Mitra Leholeh akan mendapatkan Promo Mitra Leholeh berupa cashback sebagaimana tercantum pada halaman info promo yang dapat diakses pada halaman utama Situs/Aplikasi, maupun di bagian informasi akun Mitra Leholeh</li>
                <li>Promo Mitra Leholeh dapat berubah sewaktu-waktu tanpa pemberitahuan dari Leholeh kepada Mitra Leholeh</li>
            </ol>

            <h4>H. PENGIRIMAN BARANG FISIK</h4>
            <ol>
                <li>Pengiriman barang ditujukan ke alamat yang terdaftar saat Pengguna melakukan check out. Setelah Pengguna check out, Pengguna tidak dapat mengubah alamat pengiriman tersebut.</li>
                <li>Pengiriman Barang Fisik kepada Pengguna akan dilakukan melalui penyedia jasa layanan pengiriman yang telah ditentukan oleh Leholeh.</li>
                <li>Setiap ketentuan berkenaan dengan proses pengiriman Barang Fisik adalah wewenang sepenuhnya penyedia jasa layanan pengiriman.</li>
                <li>Pengguna memahami dan menyetujui bahwa setiap permasalahan yang terjadi pada saat proses pengiriman Barang Fisik oleh penyedia jasa layanan pengiriman adalah merupakan tanggung jawab penyedia jasa layanan pengiriman.</li>
            </ol>

            <h4>I. PENOLAKAN JAMINAN DAN BATASAN TANGGUNG JAWAB</h4>
            <p>Leholeh adalah suatu perusahaan portal web yang menyediakan platform dalam menyediakan layanan kepada Pengguna untuk dapat menjadi Partner maupun Mitra Leholeh di Situs/Aplikasi. Dengan demikian transaksi yang terjadi adalah transaksi antar Pengguna, sehingga Pengguna memahami bahwa batasan tanggung jawab Leholeh secara proporsional  sebagai penyedia jasa portal web.</p>
                <p>Leholeh selalu berupaya untuk menjaga Situs/Aplikasi aman, nyaman, dan berfungsi dengan baik, tapi Leholeh tidak dapat menjamin operasi terus-menerus atau akses ke Situs/Aplikasi dapat selalu sempurna. Informasi dan data dalam Situs/Aplikasi memiliki kemungkinan tidak terjadi secara real time</p>
                <p>Pengguna setuju bahwa dengan memanfaatkan Situs/Aplikasi atas risiko Pengguna sendiri, dan Situs/Aplikasi diberikan kepada Pengguna pada <strong>"SEBAGAIMANA ADANYA"</strong> dan <strong>"SEBAGAIMANA TERSEDIA".</strong></p>
                <p>Sejauh diizinkan oleh hukum yang berlaku, Leholeh (termasuk Induk Perusahaan, direktur, dan karyawan) tidak bertanggung jawab, dan Pengguna setuju untuk tidak menuntut Leholeh bertanggung jawab, atas segala kerusakan atau kerugian (termasuk namun tidak terbatas pada hilangnya uang, reputasi, keuntungan, atau kerugian tak berwujud lainnya) yang diakibatkan secara langsung atau tidak langsung dari:</p>
            <ol>
                <li>Penggunaan atau ketidakmampuan Pengguna dalam menggunakan Situs/Aplikasi.</li>
                <li>Harga, pengiriman atau petunjuk lain yang tersedia dalam Situs/Aplikasi.</li>
                <li>Keterlambatan atau gangguan dalam Situs/Aplikasi.</li>
                <li>Kelalaian dan kerugian yang ditimbulkan oleh Pengguna.</li>
                <li>Kualitas Barang Fisik.</li>
                <li>Pengiriman Barang Fisik.</li>
                <li>Pelanggaran Hak atas Kekayaan Intelektual</li>
                <li>Perselisihan antar Pengguna.</li>
                <li>Pencemaran nama baik pihak lain.</li>
                <li>Setiap penyalahgunaan Barang Fisik yang sudah dibeli oleh Mitra Leholeh.</li>
                <li>Pengiriman untuk perbaikan Barang Fisik yang bergaransi resmi dari pihak produsen.
                Mitra Leholeh dapat membawa Barang langsung kepada pusat layanan servis terdekat dengan kartu garansi dan faktur Penggunaan.
                </li>
                <li>Virus atau perangkat lunak berbahaya lainnya <em>(bot, script, automation tool selain fitur Gold Merchant, hacking tool)</em> yang diperoleh dengan mengakses, atau menghubungkan ke Situs/Aplikasi.</li>
                <li>Gangguan, bug, kesalahan atau ketidakakuratan apapun dalam Situs/Aplikasi.</li>
                <li>Kerusakan pada perangkat keras Pengguna dari penggunaan Situs/Aplikasi.</li>
                <li>Isi, tindakan, atau tidak adanya tindakan dari pihak ketiga, termasuk terkait dengan Barang Fisik dan/atau Barang Digital yang ada dalam Situs/Aplikasi yang diduga palsu.</li>
                <li>Tindakan penegakan yang diambil sehubungan dengan akun Pengguna.</li>
                <li>Adanya tindakan peretasan yang dilakukan oleh pihak ketiga kepada akun Pengguna.</li>
            </ol>

            <h4>J. Pelepasan</h4>
            <p>Apabila Mitra Leholeh memiliki perselisihan dengan satu atau lebih Mitra Leholeh lainnya, Mitra Leholeh melepaskan Leholeh (termasuk Induk Perusahaan, Direktur, dan karyawan) dari klaim dan tuntutan atas kerusakan dan kerugian (aktual dan tersirat) dari setiap jenis dan sifatnya, yang dikenal dan tidak dikenal, yang timbul dari atau dengan cara apapun berhubungan dengan sengketa tersebut. Dengan demikian maka Mitra Leholeh dengan sengaja melepaskan segala perlindungan hukum (yang terdapat dalam undang-undang atau peraturan hukum yang lain) yang akan membatasi cakupan ketentuan pelepasan ini.</p>
            <h4>K. GANTI RUGI</h4>
            <p>Mitra Leholeh akan melepaskan Leholeh dari tuntutan ganti rugi dan menjaga Leholeh (termasuk Induk Perusahaan, direktur, dan karyawan) dari setiap klaim atau tuntutan, termasuk biaya hukum yang wajar, yang dilakukan oleh pihak ketiga yang timbul dalam hal Mitra Leholeh melanggar Syarat dan Ketentuan ini, penggunaan Situs/Aplikasi yang tidak semestinya dan/atau pelanggaran Mitra Leholeh terhadap hukum atau hak-hak pihak ketiga.</p>
            <h4>L. PILIHAN HUKUM</h4>
            <ol>
                <li>Syarat dan Ketentuan ini akan diatur oleh dan ditafsirkan sesuai dengan hukum Republik Indonesia, tanpa memperhatikan pertentangan aturan hukum.</li>
                <li>Mitra Leholeh sepakat jika terdapat sengketa yang berkaitan dengan Syarat dan Ketentuan ini, maka akan dilakukan musyawarah untuk mencapai mufakat. </li>
                <li>Bahwa apabila poin 2 tidak menghasilkan penyelesaian, maka Mitra Leholeh setuju bahwa tindakan hukum apapun atau sengketa yang mungkin timbul dari, berhubungan dengan, atau berada dalam cara apapun sehubungan dengan Situs/Aplikasi dan/atau Syarat dan Ketentuan ini akan diselesaikan secara eksklusif dalam wilayah hukum yang termasuk dalam yurisdiksi Leholeh</li>
            </ol>
            <h4>M. PEMBAHARUAN</h4>
            <p>Syarat dan Ketentuan ini mungkin diubah dan/atau diperbaharui dari waktu ke waktu tanpa pemberitahuan sebelumnya. Leholeh menyarankan agar Mitra Leholeh membaca secara seksama dan memeriksa halaman Syarat dan ketentuan ini dari waktu ke waktu untuk mengetahui perubahan apapun. Dengan tetap mengakses dan menggunakan Situs/Aplikasi, maka Mitra Leholeh dianggap menyetujui perubahan-perubahan dalam Syarat dan Ketentuan ini.</p>
            <h4>O. PENUTUP</h4>
            <ol>
                <li>Pengguna dan Mitra Leholeh telah mengerti serta memahami tiap maksud dan tujuan yang dimaksud dalam Syarat dan Ketentuan Mitra Leholeh ini.</li>
                <li>Segala syarat-syarat dan ketentuan-ketentuan ini berlaku serta mengikat seluruh pihak.</li>
                <li>Hal-hal yang belum diatur dalam Syarat dan Ketentuan ini, akan diatur dalam suatu aturan yang merupakan bagian yang tidak terpisahkan dan mempunyai kekuatan hukum yang sama dengan syarat dan ketentuan ini secara tertulis.</li>
            </ol>
            <p>Demikian Syarat dan Ketentuan ini dibuat secara sadar dan tanpa ada tekanan serta dipahami oleh Seluruh Pihak. Syarat dan Ketentuan ini dapat dipergunakan sebagai bukti hukum.</p>
            <p style={{textAlign:'right'}}>Dibuat di		: KOTA BANDUNG</p>
            <p style={{textAlign:'right'}}>Pada Tanggal		: 20 November 2020</p>
            
            <div style={{textAlign:'right',height:'100px', display:'flex',width:'100%',justifyContent:'flex-end',alignItems:'flex-end'}}>
            <p style={{textAlign:'right', fontWeight:'bold'}}>PT. LEHOLEH RASA NUSANTARA JAYA</p>             
            </div>
            
        </div>
    );
}

export default TermCondition;