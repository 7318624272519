import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../img/logo.png';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
// import IconButton from '@material-ui/core/IconButton';
// import Popover from '@material-ui/core/Popover';
// import ChatIcon from '@material-ui/icons/Chat';
// import EmailIcon from '@material-ui/icons/Email';
import playstoreIcon from '../img/en_badge_web_generic.png';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';


const useStyles = makeStyles((theme) => ({ 
  Container:{
      borderTop:"2px solid #ddd",
      padding:"10px",
      backgroundColor:"#fff",
  },
  fcstore:{
    color:'#A70B0B',
    fontWeight:'700',
    fontSize:'16px'
   },
    linkFooter:{
        display: 'block',
        color: '#333',
        margin:'10px 30px 0 0',
        '&:hover': {
            textDecoration: 'none',
          },  
    },
    borderBtn:{
        border:'1px solid #333',
        borderRadius:'10px',
        margin:'5px 6px 0 0',
        minHeight:'35px',
        padding:'0 27px'
     
    },
    textTittle:{
        fontSize:'13px'
    }

}));

export default function Footer() {
  const classes = useStyles();
  const preventDefault = (event) => event.preventDefault();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <React.Fragment>
        <CssBaseline />
            <div className={classes.Container}>
                <Container>
                <Grid container spacing={0}>                  
                        <Grid item xs={12} md={3}>
              <img src={logo} style={{ height: 80, width: 80 }} alt="logo" /><br /><br />
                            <Typography gutterBottom variant="h6" component="h6" className={classes.textTittle}>
                                 Merupakan situs jual beli oleh - oleh <br />daerah seluruh Indonesia.
                            </Typography> 
                           
                        </Grid> 
                        <Grid item xs={12} md={3}>
                            <Typography gutterBottom variant="h6" component="h6" className={classes.textTittle}>
                            <div className={classes.fcstore}>Kontak Kami </div>
                            No Telp / WA : <br />+62 812-8888-3745, <br />+62 812-8888-3752<br />
                            Email : cs@leholeh.co.id, leholehnusantara@gmail.com
                            </Typography> 
                        </Grid> 
                        <Grid item xs={12} md={3}>                          
                        <Typography gutterBottom variant="h6" component="h6" className={classes.textTittle}>
                            <div className={classes.fcstore}>Lokasi Kami </div>                           
                            Menara Citibank, Lantai 3, Kav. II/BA No. 1 Pondok Indah, Jl. Metro Pondok Indah, RT.3/RW.14, Pd. Pinang, Kec. Kby. Lama, <br /> Kota Jakarta Selatan,<br /> Daerah Khusus Ibukota Jakarta 12310<br />
                            </Typography> 
                        </Grid> 
                        <Grid item xs={12} md={3}>
                            <Typography gutterBottom variant="h6" component="h6" className={classes.fcstore}>
                               Unduh Aplikasi<br/>   
                               <Link href="https://play.google.com/store/apps/details?id=co.id.leholeh.leholeh_mobile_app" target="_blank" className={classes.linkFooter}> 
                                 <img src={playstoreIcon} height="60"/>  
                               </Link>                     
                            </Typography> 
                            <Typography gutterBottom variant="h6" component="h6" className={classes.textTittle}>
                                <div className={classes.fcstore}>Ikuti Kami</div>
                                <div style={{display:'flex', justifyContent:'flex-start'}}>
                                <Link href="https://www.facebook.com/leholeh.indonesia/" target="_blank" className={classes.linkFooter}>
                                    <FacebookIcon/>
                                </Link>
                                <Link href="https://www.instagram.com/leholeh.indonesia/" target="_blank" className={classes.linkFooter}>
                                   <InstagramIcon/>
                                </Link>
                                </div>
                            </Typography> 
                        </Grid>                     
                </Grid>
                </Container>
                
            </div>
    </React.Fragment>
  );
}

// import React,{useState} from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import logo from '../img/logo.png';
// import Typography from '@material-ui/core/Typography';
// import Grid from '@material-ui/core/Grid';
// import Container from '@material-ui/core/Container';
// import CssBaseline from '@material-ui/core/CssBaseline';
// import Link from '@material-ui/core/Link';
// import IconButton from '@material-ui/core/IconButton';
// import Popover from '@material-ui/core/Popover';
// import ChatIcon from '@material-ui/icons/Chat';
// import EmailIcon from '@material-ui/icons/Email';

// const images = [
//     {
//       url: '/images/payment/bca.png',
//       title: 'BCA',
//     },
//     {
//       url: '/images/payment/bri.png',
//       title: 'BRI',
//     },
//     {
//       url: '/images/payment/mandiri.png',
//       title: 'Mandiri',
//     },
    
//   ];
// const useStyles = makeStyles((theme) => ({ 
//   paper: {
//     padding: theme.spacing(2),
//     textAlign: 'left',
//     color: theme.palette.text.secondary,
//   },
//   typography: {
//     padding: theme.spacing(1),
//     display:'flex',
//   },
//   Container:{
//       borderTop:"2px solid #ddd",
//       padding:"10px",
//       backgroundColor:"#eee",
//   },
//   fcstore:{
//     color:'#ab0004',
//     fontWeight:'700',
//    },
//     linkFooter:{
//         width: '100%',
//         display: 'block',
//         color: '#333',
//         margin:'10px 0',
//         '&:hover': {
//             textDecoration: 'none',
//           },  
//     },
//     borderBtn:{
//         border:'1px solid #333',
//         borderRadius:'10px',
//         margin:'5px 6px 0 0',
//         minHeight:'35px',
//         padding:'0 27px'
     
//     },
//     textTittle:{
//         fontSize:'13px'
//     }

// }));

// export default function Footer() {
//   const classes = useStyles();
//   const preventDefault = (event) => event.preventDefault();
//   const [anchorEl, setAnchorEl] = useState(null);
//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//   const handleClose = () => {
//     setAnchorEl(null);
//   };
//   const open = Boolean(anchorEl);
//   const id = open ? 'simple-popover' : undefined;
//   return (
//     <React.Fragment>
//         <CssBaseline />
//             <div className={classes.Container}>
//                 <Container>
//                 <Grid container spacing={0}>                  
//                         <Grid item xs={12} md={3}>
//                             <img src={logo} alt="logo"/><br /><br />
//                             <Typography gutterBottom variant="h6" component="h6" className={classes.textTittle}>
//                                  Merupakan situs jual beli oleh - oleh <br />daerah seluruh Indonesia.
//                             </Typography> 
//                             <br />
//                             <Typography gutterBottom variant="h6" component="h6" className={classes.textTittle}>
//                             <strong>Kontak Kami </strong><br />
//                             No Telp / WA : +62 812-8888-3745, +62 812-8888-3752<br />
//                             Email : cs@leholeh.co.id, leholehnusantara@gmail.com
//                             </Typography> 
//                             <br />
//                             <Typography gutterBottom variant="h6" component="h6" className={classes.textTittle}>
//                             <strong>Lokasi Kami </strong><br />                            
//                             Menara Citibank, Lantai 3, Kav. II/BA No. 1 Pondok Indah, Jl. Metro Pondok Indah, RT.3/RW.14, Pd. Pinang, Kec. Kby. Lama, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12310<br />
//                             </Typography> 
//                         </Grid> 
//                         <Grid item xs={12} md={3}>
//                             <Typography gutterBottom variant="h6" component="h6" className={classes.textTittle}>
//                                 <div className={classes.fcstore}>LAYANAN PELANGGAN</div>
//                                 <Link href="javascript:void(0)" onClick={handleClick} className={classes.linkFooter}>
//                                     Kontak Kami
//                                 </Link>
                                
//                                 <Link href="/faq" className={classes.linkFooter}>
//                                    Cara Belanja
//                                 </Link>
//                                 <Link href="/faq" className={classes.linkFooter}>
//                                     Pengiriman Barang
//                                 </Link>
//                             </Typography> 
//                         </Grid> 
//                         <Grid item xs={12} md={3}>
//                             <Typography gutterBottom variant="h6" component="h6" className={classes.textTittle}>
//                                 <div className={classes.fcstore}>KATEGORI PRODUK</div>
//                                 <Link href="/foodcategory/food" className={classes.linkFooter}>
//                                     Kuliner
//                                 </Link>
//                                 <Link href="/souvcategory/souvenir" className={classes.linkFooter}>
//                                    Souvenir
//                                 </Link>
//                             </Typography> 
//                         </Grid> 
//                         <Grid item xs={12} md={3}>
//                             <Typography gutterBottom variant="h6" component="h6" className={classes.textTittle}>
//                                 <div className={classes.fcstore}>MEDIA SOSIAL</div>
//                                 <Link href="https://www.facebook.com/leholeh.indonesia/" target="_blank" className={classes.linkFooter}>
//                                     Facebook Leholeh Indonesia
//                                 </Link>
//                                 <Link href="https://www.instagram.com/leholeh.indonesia/" target="_blank" className={classes.linkFooter}>
//                                    Instagram Leholeh Indonesia
//                                 </Link>
//                             </Typography> 
//                         </Grid>
//                         <Grid item xs={12} md={12}>
//                             <Typography gutterBottom variant="h6" component="h6" className={classes.fcstore}>
//                                 <strong>PEMBAYARAN YANG DIDUKUNG </strong><br />
//                                 {images.map((image) => (
//                                 <IconButton aria-label="button" className={classes.borderBtn}>
//                                     <img src={image.url} width="50" alt="Payment"/>
//                                 </IconButton>
//                                 ))}
//                             </Typography> 
//                         </Grid>                        
//                 </Grid>
//                 </Container>
//                 <Popover
//                 id={id}
//                 open={open}
//                 anchorEl={anchorEl}
//                 onClose={handleClose}
//                 anchorOrigin={{
//                     vertical: 'bottom',
//                     horizontal: 'left',
//                 }}
//                 transformOrigin={{
//                     vertical: 'top',
//                     horizontal: 'left',
//                 }}
//                 >
//                 <Typography className={classes.typography}>
//                     <Link href="https://wa.me/6281288883745" style={{margin:'0 20px 0 0', color:'#098d00'}}>
//                         <ChatIcon/>
//                     </Link>
//                     <Link href="mailto:leholehnusantara@gmail.com" style={{color:'#ab0004'}}>
//                         <EmailIcon/>
//                     </Link>                    
//                 </Typography>
//                 </Popover>
//             </div>
//     </React.Fragment>
//   );
// }
